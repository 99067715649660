import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef
} from "@angular/core";

import { Subscription } from "rxjs";
import { IMqttMessage, MqttService } from "ngx-mqtt";
export class TestObject {
pulseRate: string;
spO2: string;
temperature : string;
respiration: string;
systolic:string;
diastolic: string;
  heartRate: any;
}
@Component({
  selector: 'app-oximeter',
  templateUrl: './oximeter.component.html',
  styleUrls: ['./oximeter.component.css']
})

export class OximeterComponent implements OnInit,OnDestroy {

  private subscription: Subscription;
  topicname: "OXIMETER";
  msg: any;
  display = false;
  qrInfo: any;
  isConnected: boolean = false;
@ViewChild("msglog", { static: true }) msglog: ElementRef;
testObject: TestObject;
  pulseRate: any;
  heartRate:any;
  temperature: string;
  systolic: string;
  spO2: string;
  respiration: string;
  diastolic: string;

  constructor(private _mqttService: MqttService) {
    _mqttService.connect({username:'PetalHosUser',password:'PetalHosEmq@20#19!'});
 
  }

  ngOnInit(): void {
    this.subscribeNewTopic();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeNewTopic(): void {
    console.log("inside subscribe new topic");
    this.subscription = this._mqttService
      .observe("OXIMETER")
      .subscribe((message: IMqttMessage) => {       
         this.qrInfo=  message.payload.toString();
this.testObject = new TestObject();
this.testObject=JSON.parse(this.qrInfo);

if (this.testObject.pulseRate!=undefined){
  this.pulseRate=this.testObject.pulseRate;
}

if (this.testObject.heartRate!=undefined){
  this.heartRate=this.testObject.heartRate;
}
if (this.testObject.spO2!=undefined){
  this.spO2=this.testObject.spO2
}

if (this.testObject.systolic!=undefined){
  this.systolic=this.testObject.systolic
}
if (this.testObject.temperature!=undefined){
  this.temperature=this.testObject.temperature
}
if (this.testObject.respiration!=undefined){
  this.respiration=this.testObject.respiration
}

if (this.testObject.diastolic!=undefined){
  this.diastolic=this.testObject.diastolic
}
      });
}

}

