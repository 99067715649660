<html>
  <body>
    <br>
    <br>
    <h1>OXIMETER DATA</h1>
    <div class="bg">
      <br>
      <br>
      <div class="preference0">
        <label for="spO2">SpO2 :
         <input [(ngModel)]="spO2" [value]="spO2"  id="sp02" placeholder="- -" style="border:none" disabled/>
         </label>
       </div>
       <br>
       <br>
       <br>
       <br>
       <div  class="preference1" >
        <label for="systolic">SYS :
       <input [(ngModel)]="systolic" [value]="systolic" id="systolic" placeholder="- -" style="border:none" disabled/>
     </label>
       </div>
       <br>
       <br>
       <br>
       <br>
    <div class="preference2">
      <label  for="respiration">RESP :
    <input [(ngModel)]="respiration" [value]="respiration" id="respiration" placeholder="- -" style="border:none" disabled/>
    </label>
    </div>
    </div>
    <div class="bg2">
      <br>
      <br>
      <div class="preference3">
        <label class="l1" for="pulseRate">
       <input class="input1" [(ngModel)]="pulseRate" [value]="pulseRate" id="pulseRate" placeholder="- -" style="border:none;"  disabled/>
     : PR</label>
    </div>
    <br>
    <br>
    <br>
    <div class="preference3">
      <label class="l1" for="heartRate">
     <input class="input1" [(ngModel)]="heartRate" [value]="heartRate" id="heartRate" placeholder="- -" style="border:none;"  disabled/>
   : HR</label>
  </div>
  <br>
  <br>
  <br>
    <div class="preference4">
      <label class="l1" for="diastolic">
      <input class="input1" [(ngModel)]="diastolic" [value]="diastolic" id="diastolic" placeholder="- -" style="border:none" disabled/>
    : DIA</label>
      </div>
      <br>
      <br>
      <br>
     <div class="preference5">
      <label class="l1" for="temperature">
     <input class="input2" [(ngModel)]="temperature" [value]="temperature" id="temperature" placeholder="- -" style="border:none" disabled/>
    : TEMP </label>
      </div>
    </div>
  </body>
  </html>
