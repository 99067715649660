import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MqttModule, IMqttServiceOptions } from "ngx-mqtt";
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material/grid-list';
import { OximeterComponent } from './oximeter/oximeter.component';


export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'device.petalred.com',
  port: 8084,
  protocol: 'wss',
  path: '/mqtt'
}

@NgModule({
  declarations: [
    AppComponent,
    OximeterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    BrowserAnimationsModule,
    MatGridListModule,
    FormsModule,
  
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
