import {
  Component,
 
  OnInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import { FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { IMqttMessage, MqttService } from "ngx-mqtt";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  ngOnDestroy(): void {
 
  }
  ngOnInit(): void {

  }
  title = 'OxiMeter';

}
